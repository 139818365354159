export const decodeOtherLanguage = function (strToDecode) {
  if (!strToDecode || strToDecode == '') {
    return '';
  }
  const result = decodeURIComponent(escape(window.atob(strToDecode)));
  return result === 'null'?'':result;
};


export const showEmbedFooter = function() {
    const url = new URL(window.location);
    let showEmbedFooter = false;
    if (url) {
      const params = new URLSearchParams(url.search);
      if (params) {
        showEmbedFooter = (params.get("embed") && params.get("embed") === 'true')?true:false;
        console.log('showFooter ', showEmbedFooter);
      }
    }
    return showEmbedFooter;
  };


export const getUrlWord = function (urlPath, hash = null) {
  // [hash removal] remove this after hash migration
  if (hash && hash != '') {
    return hash.replace(/(^#)/g, "");
  }
  let urlArray = urlPath.split('/');
  if (urlArray[1] && urlArray[1] != '' && urlArray[2] && urlArray[2] != '') {
    return `/${urlArray[1]}/${urlArray[2]}`;
  }
  console.log('window', '__CURL_' + window.location.hostname.replace(/\./g, "_"));
  return '__CURL_' + window.location.hostname.replace(/\./g, "_");
};

export const getTippingUserSelections = function () {
  return localStorage.getItem('tippingUserSelections') && JSON.parse(localStorage.getItem('tippingUserSelections'));
}