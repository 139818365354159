import {browserHistory} from 'react-router';

const initialState = {
    dataAvailable: false,
    rounds: [],
    currentRound: undefined,
    loadingLeaderboards: false,
    fullLeaderboards: [],
    partialLeaderboards: [],
    tippingLeaderboards: [],
    newRender: undefined,
    pageLoading: true,
    previousEnabled: false,
    nextEnabled: false,
    shareSubmitted: false,
    shareSubmittng: false,
    omniBlocks: [],
    footers: [],
    dynamicRender: {
        dynamicSubmitted: false,
        dynamicSubmitting: false,
        dynamicRenderUrl: ''
    },
    compSelectionData:  {},
    showCampaignLoadPopup: false,
    popupLoading: false
};

export default (state = initialState, action) => {

    switch (action.type) {
        case 'SELET_NEW_ROUND': {
            return {
                ...state,
                currentRound: action.payload,
                newRender: undefined
            }
        }
        case 'GET_ALL_ROUNDS_START': {
            return {
                ...state,
                dataAvailable: false,
                rounds: [],
                currentRound: undefined,
                newRender: undefined
            }
        }
        case 'GET_ALL_ROUNDS_FINISH': {

            const success = `${action.payload.errorCode}` === "0";
            const rounds = success && action.payload.intervals ?
                action.payload.intervals.filter(r => !r.removed) : [];

            rounds.forEach(r => {
                let startDate = new Date(r.start);
                let endDate = new Date(r.end);
                let now = new Date();
                r.voteOpen = startDate < now && endDate > now && r.enabled;
            });

            const nextOpenRound = rounds.find(r => r.voteOpen);
            const currentRound = rounds ? nextOpenRound ? nextOpenRound : rounds[rounds.length - 1] : undefined;

            return {
                ...state,
                dataAvailable: true,
                rounds,
                currentRound,
                data: action.payload,
                newRender: undefined
            }
        }

        case 'GET_LEADERBOARDS_START': {
            return {
                ...state,
                loadingLeaderboards: true,
                fullLeaderboards: [],
                newRender: undefined
            }
        }
        case 'GET_LEADERBOARDS_FINISH': {
            console.log("action.payload", action.payload)
            return {
                ...state,
                loadingLeaderboards: false,
                fullLeaderboards: action.payload,
                newRender: undefined
            }
        }
        case 'GET_LEADERBOARD_STANDINGS_START':
        case 'GET_TIPPING_STANDINGS_START': {
            return {
                ...state,
                loadingLeaderboards: true,
                tippingLeaderboards: {},
                newRender: undefined,
                pageLoading: true,
                previousEnabled: false,
                nextEnabled: false
            }
        }
        case 'GET_LEADERBOARD_STANDINGS_FINISH':
        case 'GET_TIPPING_STANDINGS_FINISH': {
            console.log("action.payload", action.payload)
            return {
                ...state,
                loadingLeaderboards: false,
                tippingLeaderboards: action.payload,
                newRender: undefined,
                pageLoading: false,
                previousEnabled: (action.payload && action.payload.page && action.payload.page > 0)
                    ? true : false,
                nextEnabled: (action.payload && (((action.payload.page+1)*action.payload.limit) < action.payload.total))
                    ? true : false
            }
        }
        case 'SUBMIT_VOTE_START': {
            return {
                ...state,
                submitting: true,
                submitted: false,
                submittedIdInterval: undefined,
                submittedIdVote: undefined,
                shareContentUrlBySocialNetworkId: undefined
            }
        }
        case 'SUBMIT_VOTE_FINISH': {
            console.log("action.payload", action.payload);
            return {
                ...state,
                submitting: false,
                submitted: true,
                submittedIdInterval: action.payload.idInterval,
                submittedIdVote: action.payload.idVote,
                shareContentUrlBySocialNetworkId: undefined
            }
        }
        case 'POST_ERROR': {
            return {
                ...state,
                submitting: false,
                submitted: false,
                submittedIdInterval: undefined,
                shareContentUrlBySocialNetworkId: undefined
            }
        }
        case 'RENDER_SHARE_START':
            return {
                ...state,
                shareSubmitted: false,
                shareSubmittng: true,
                shareContentUrlBySocialNetworkId: undefined
            }
        case 'RENDER_SHARE_FINISH':
            console.log("RENDER_SHARE_FINISH", action.payload)
            return {
                ...state,
                shareSubmitted: true,
                shareSubmittng: false,
                shareContentUrlBySocialNetworkId: action.payload.enabledSocialNetworks
            }
        case 'DYNAMIC_RENDER_START':
            return {
                ...state,
                dynamicRender: {
                    dynamicSubmitted: false,
                    dynamicSubmitting: true,
                    dynamicRenderUrl: ''
                }
            }
        case 'DYNAMIC_RENDER_FINISH':
            console.log("DYNAMIC_RENDER_FINISH", action.payload);
            return {
                ...state,
                dynamicRender: {
                    dynamicSubmitted: true,
                    dynamicSubmitting: false,
                    dynamicRenderUrl: action.payload && action.payload.dynamicRenderUrl,
                }
            }
        case 'GET_COMP_SELECTION_START': {
            return {
                ...state,
                compSelectionData: {},
                popupLoading: true,
                showCampaignLoadPopup: false
            }
        }
        case 'GET_COMP_SELECTION_FINISH': {
            console.log("comp-selection", action.payload);

            return {
                ...state,
                compSelectionData: action.payload,
                popupLoading: false,
                showCampaignLoadPopup: true
            }
        }
        case 'GET_OMNIBLOCKS_FINISH': {
            console.log("omni-blocks-1", action.payload)
            return {
                ...state,
                omniBlocks: action.payload,
            }
        }
        case 'GET_FOOTER_FINISH': {
            console.log("footer-comp", action.payload)
            return {
                ...state,
                footers: action.payload,
            }
        }
        default:
            return state
    }
}
