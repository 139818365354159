import RestApiClient from './RestApiClient';
import {buildHeaders,generateUrlApiV2} from "../helpers/MiddlewareHelpers";
import axios from 'axios';
import { data } from 'jquery';

const trackApiCall = (idCompetition, parameters, successCallback, catchCallback) => {

    RestApiClient({
        apiV2: true,
        relativeUrl: `/event/${idCompetition}`,
        type: 'POST',
        successCallback,
        catchCallback,
        payload: parameters
    });
}

const optinApi = (parameters, token, successCallback, catchCallback) => {

    RestApiClient({
        relativeUrl: '/optin',
        type: 'POST',
        successCallback,
        catchCallback,
        payload: parameters,
        authenticated: true,
        token: token
    });
}

const optinSocialApi = (parameters, token, successCallback, catchCallback) => {

    RestApiClient({
        apiV2: true,
        relativeUrl: `/terms`,
        type: 'PUT',
        successCallback,
        catchCallback,
        payload: parameters,
        authenticated: true,
        token: token
    });
}

const loginFace = (parameters, successCallback, catchCallback) => {

    RestApiClient({
        relativeUrl: '/facelogin',
        type: 'POST',
        successCallback,
        catchCallback,
        payload: parameters
    });
}

const retrieveTwitterAccessToken = (parameters, successCallback, catchCallback) => {

    RestApiClient({
        relativeUrl: '/twitter/login',
        type: 'POST',
        successCallback,
        catchCallback,
        payload: parameters
    });
}


const retrieveTwitterToken = (parameters, successCallback, catchCallback) => {

    RestApiClient({
        relativeUrl: `/twitter/requesttoken/${parameters.idCompetition}`,
        type: 'GET',
        successCallback,
        catchCallback,
        payload: parameters
    });
}

const login = (parameters, successCallback, catchCallback) => {

    RestApiClient({
        relativeUrl: '/login',
        type: 'POST',
        successCallback,
        catchCallback,
        payload: parameters
    });
}

const logoff = (parameters, successCallback, catchCallback) => {

    RestApiClient({
        relativeUrl: '/login', // TODO: replace by token invalidation endpoint
        type: 'POST',
        successCallback,
        catchCallback,
        payload: parameters
    });
}

const register = (parameters, successCallback, catchCallback) => {

    RestApiClient({
        relativeUrl: '/registerlogin',
        type: 'POST',
        successCallback,
        catchCallback,
        payload: parameters
    });
}

const forgotCall = (parameters, successCallback, catchCallback) => {

    RestApiClient({
        relativeUrl: '/recover',
        type: 'POST',
        successCallback,
        catchCallback,
        payload: parameters
    });
}

const resetCall = (parameters, successCallback, catchCallback) => {

    RestApiClient({
        relativeUrl: '/reset',
        type: 'POST',
        successCallback,
        catchCallback,
        payload: parameters
    });
}

const webhookRegisterApi = (parameters, successCallback, catchCallback) => {

    RestApiClient({
        apiV2: true,
        relativeUrl: `/competition/${parameters.idCompetition}/registerWebhook`,
        type: 'POST',
        successCallback,
        catchCallback,
        payload: parameters
    });
}

const emailConfirmation = (parameters, successCallback, catchCallback) => {

    RestApiClient({
        relativeUrl: '/confirmation',
        type: 'POST',
        successCallback,
        catchCallback,
        payload: parameters
    });
}

const getActiveCompetitions = (hash, successCallback, catchCallback) => {

    RestApiClient({
        apiV2: true,
        relativeUrl: `/competition/hash/${hash.replace(/\//g, "_")}`,
        type: 'GET',
        successCallback,
        catchCallback
    });
}

const getLeaderboardsApi = (hash, successCallback, catchCallback) => {

    RestApiClient({
        apiV2: true,
        relativeUrl: `/competition/${hash}/leaderboards`,
        type: 'GET',
        successCallback,
        catchCallback
    });
}

const getLeaderboardStandingsAuthenticatedApi = (idCompetition, token, data, successCallback, catchCallback) => {
    if (!data || data.page < 0 || !data.limit) {
        return false;
    }
    RestApiClient({
        apiV2: true,
        relativeUrl: `/competition/getLeaderboardStandingsWithUser/${idCompetition}?page=${data.page}&limit=${data.limit}`,
        type: 'GET',
        successCallback,
        authenticated: true,
        token: token,
        catchCallback
    });
}

const getLeaderboardStandingsApi = (idCompetition, token, data, successCallback, catchCallback) => {
    if (!data || data.page < 0 || !data.limit) {
        return false;
    }
    RestApiClient({
        apiV2: true,
        relativeUrl: `/competition/getLeaderboardStandings/${idCompetition}?page=${data.page}&limit=${data.limit}`,
        type: 'GET',
        successCallback,
        catchCallback
    });
}

const getTippingStandingsAuthenticatedApi = (idCompetition, token, data, successCallback, catchCallback) => {
    if (!data || data.page < 0 || !data.limit) {
        return false;
    }
    RestApiClient({
        apiV2: true,
        relativeUrl: `/competition/getTippingStandingsWithUser/${idCompetition}?page=${data.page}&limit=${data.limit}`,
        type: 'GET',
        successCallback,
        authenticated: true,
        token: token,
        catchCallback
    });
}

const getTippingStandingsApi = (idCompetition, token, data, successCallback, catchCallback) => {
    if (!data || data.page < 0 || !data.limit) {
        return false;
    }
    RestApiClient({
        apiV2: true,
        relativeUrl: `/competition/getTippingStandings/${idCompetition}?page=${data.page}&limit=${data.limit}`,
        type: 'GET',
        successCallback,
        catchCallback
    });
}

const submit = (parameters, token, successCallback, catchCallback) => {

    // RestApiClient({
    //     relativeUrl: '/vote/leaderboard',
    //     type: 'POST',
    //     successCallback,
    //     catchCallback,
    //     payload: parameters,
    //     authenticated: true,
    //     token: token
    // });

    // Nodejs API

    RestApiClient({
        apiV2: true,
        relativeUrl: '/competition/vote-leaderboard',
        type: 'POST',
        successCallback,
        catchCallback,
        payload: parameters,
        authenticated: true,
        token: token
    });
}

const reValidateToken = (parameters, successCallback, catchCallback) => {
    RestApiClient({
        relativeUrl: '/revalidate',
        type: 'POST',
        successCallback,
        catchCallback,
        payload: parameters
    });
}

const getLanguageApiCall = (language,successCallback, catchCallback) => {
    const headers = buildHeaders();
    const url = generateUrlApiV2('/locale/getLocaleData/'+language);
    axios.get(url, {
      method: 'GET',
      crossdomain: true,
      headers: headers
    })
      .then(successCallback)
      .catch(catchCallback);
  }

const renderShareApiCall = (parameters, successCallback, catchCallback) => {

    const headers = buildHeaders();
    const url = generateUrlApiV2(`/competition/renderShareForComp/${parameters.idCompetition}`);
    axios.post(url, parameters, {
        method: 'POST',
        crossdomain: true,
        headers: headers,
        data: {
        idVote: parameters.idVote
        }
    })
    .then(successCallback)
    .catch(catchCallback);;
}

const trackBlockClickApiCall = (parameters, successCallback, catchCallback) => {

    const headers = buildHeaders();
    const url = generateUrlApiV2(`/event/${parameters.idCompetition}/clickBlock`);
    axios.post(url, parameters, {
        method: 'POST',
        crossdomain: true,
        headers: headers,
        data: {
            value: parameters.idBlock,
            type: parameters.type
        }
    })
    .then(successCallback)
    .catch(catchCallback);;
}

const dynamicRenderApiCall = (parameters, successCallback, catchCallback) => {
    // Java API

    // RestApiClient({
    //     relativeUrl: '/vote/dynamic-render',
    //     type: 'POST',
    //     successCallback,
    //     catchCallback,
    //     payload: parameters
    // });

    // Nodejs API

    const headers = buildHeaders();
    const url = generateUrlApiV2(`/dynamic-render`);
    axios.post(url, parameters, {
        method: 'POST',
        crossdomain: true,
        headers: headers
    })
    .then(successCallback)
    .catch(catchCallback);
}

const getOmnihubBlocksApi = (idCompetition, successCallback, catchCallback) => {

    RestApiClient({
        apiV2: true,
        relativeUrl: `/competition/getOmnihubBlocks/${idCompetition}/0`,
        type: 'GET',
        successCallback,
        catchCallback
    });
}

const getFootersForCompApi = (idCompetition, successCallback, catchCallback) => {

    RestApiClient({
        apiV2: true,
        relativeUrl: `/competition/getFootersForComp/${idCompetition}/0`,
        type: 'GET',
        successCallback,
        catchCallback
    });
}

const getAllCompSelectionDataFromOmnihubApi = (parameters, successCallback, catchCallback) => {
    RestApiClient({
        apiV2: true,
        relativeUrl: '/competition/getAllCompSelectionDataFromOmnihub',
        type: 'POST',
        successCallback,
        catchCallback,
        payload: parameters
    });
}

export {
    login, loginFace, retrieveTwitterToken,
    retrieveTwitterAccessToken,
    logoff, register, emailConfirmation,
    getActiveCompetitions, submit, getLeaderboardsApi,
    reValidateToken, forgotCall, resetCall, optinApi,
    optinSocialApi,
    trackApiCall, webhookRegisterApi,
    getLanguageApiCall,
    getTippingStandingsAuthenticatedApi,
    getTippingStandingsApi,
    renderShareApiCall,
    dynamicRenderApiCall,
    getLeaderboardStandingsAuthenticatedApi,
    getLeaderboardStandingsApi,
    getOmnihubBlocksApi,
    getFootersForCompApi,
    trackBlockClickApiCall,
    getAllCompSelectionDataFromOmnihubApi
}
