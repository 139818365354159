import React, { Component } from "react";
import { Checkbox, ControlLabelButton, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import RoundSelector from "./components/RoundSelector";
import RoundInfo from "./components/RoundInfo";
import Voting from "./components/Voting";
import VoteStatus from "./components/VoteStatus";
import Login from "./components/Login";
import TotalLeaderboard from "./components/TotalLeaderboard";
import {loadLocale} from "../actions/LocaleAction";
import FadeLoader from "react-spinners/FadeLoader";


import {
  optinCall,
  optinSocialCall,
  signInTwitterFinish,
  startReset,
  verifyEmail
} from "../actions/AuthActions";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/fontawesome-free-solid/index";
import "url-search-params-polyfill";
import { getAllRounds, getLeaderboardStandings, getTippingStandings } from "../actions/CompetitionActions";
import {
  trackOptin,
  trackSession,
  trackVote
} from "../actions/TrackingActions";

import { API_V2_URL, SHOW_USERNAME_NON_TIPPING } from "../util/Constants";

import $ from "jquery";
import ReactDOM from "react-dom";
import MarketingButtons from "./components/MarketingButtons";
import {Trans,
    initReactI18next,
    withTranslation,
  } from "react-i18next";
  import i18n from "i18next";
import { getUrlWord } from "../util/helpers/Helpers";
import { Cookies } from 'react-cookie';

class App extends Component {
  constructor(props) {
    super(props);

    const params = new URLSearchParams(props.location.search);
    const hash = getUrlWord(props.match.url, props.location.hash);
    const oauthToken = params.get("oauth_token");
    const oauthVerifier = params.get("oauth_verifier");
    const validationString = params.get("validationString");
    const recoverPass = params.get("recoverPass");
    console.log("recoverPass", recoverPass);
    console.log("hash", hash);

    if (oauthToken && oauthVerifier) {
      if (hash.includes("/")) {
        props.history.push(hash);
      } else if (hash.includes("__CURL_")) {
        props.history.push('');
      } else {
        // [hash removal] remove this after hash migration
        props.history.push(`/#${hash}`);
      }
      props.dispatch(
        signInTwitterFinish({ oauthToken, oauthVerifier, hash }, props.dispatch)
      );
    }

    if (validationString) {
      if (hash.includes("/")) {
        props.history.push(hash);
      } else if (hash.includes("__CURL_")) {
        props.history.push('');
      } else {
        // [hash removal] remove this after hash migration
        props.history.push(`/#${hash}`);
      }
      props.dispatch(verifyEmail({ validationString }, props.dispatch));
    }

    if (recoverPass) {
      if (hash.includes("/")) {
        props.history.push(hash);
      } else if (hash.includes("__CURL_")) {
        props.history.push('');
      } else {
        // [hash removal] remove this after hash migration
        props.history.push(`/#${hash}`);
      }
      props.dispatch(startReset({ recoverPass }, props.dispatch));
    }

    this.state = {
      tncs: false,
      optin: false,
      codeInjected: false,
      loader:false
    };

    this.handleAcceptTncs = this.handleAcceptTncs.bind(this);
    this.childTwo = React.createRef();
    this.setAgeRestrictionAcceptance = this.setAgeRestrictionAcceptance.bind(this);
  }

  setAgeRestrictionAcceptance = () => {
    const cookies = new Cookies();
    console.log('cookie-set-here')
    cookies.set('age-restriction-' + this.props.competition.data.id, true);
    this.setState({
      hideAgeRestrictionPopup: true
    });
  }

  showAgeRestrictionPopupError = () => {
    this.setState({
      showAgeRestrictionPopupErr: true
    });
  }

  resizeContainer = () => {
    const { competition: comp } = this.props;
    const { data } = comp;

    let useStyle = false;
    let useStyleBg = '';

    if (data) {
      if(data.bgColor){
        document.getElementById('root').style.backgroundColor = data.bgColor;
        document.getElementById('root').style.minHeight = '100vh';
      }
      const iw = window.innerWidth;
      if (data.desktopBg && iw > 1030) {
        // document.body.style.backgroundImage = `url(${data.desktopBg.file})`;
        useStyle = true;
        useStyleBg = data.desktopBg.file;
      } else if (data.tabletBg && iw <= 1030 && iw >= 400) {
        // TO-REMOVE: Added only for HSBC campaign
        if (data.id && [2268, 2301, 4954, 4555].includes(data.id) && data.mobileBg && iw <= 450) {
          // document.body.style.background = `url(${data.mobileBg.file}) no-repeat center center fixed`;
          useStyle = true;
          useStyleBg = data.mobileBg.file;
        } else {
          // document.body.style.backgroundImage = `url(${data.tabletBg.file})`;
          useStyle = true;
          useStyleBg = data.tabletBg.file;
        }
      } else if (data.mobileBg && iw < 400) {
          // document.body.style.background = `url(${data.mobileBg.file}) no-repeat center center fixed`;
        // if (data.id && [2268, 2301, 4954, 4555].includes(data.id)) {
          useStyle = true;
          useStyleBg = data.mobileBg.file;
        // } else {
        //   document.body.style.backgroundImage = `url(${data.mobileBg.file})`;
        // }
      }

      // iphone/ipad background fix
      if (useStyle) {
        const styles = `
            body {
            background: url(${useStyleBg}) no-repeat center center fixed;
            }
            
            body:after{
                content:"";
                position:fixed; /* stretch a fixed position to the whole screen */
                top:0;
                height:100vh; /* fix for mobile browser address bar appearing disappearing */
                left:0;
                right:0;
                z-index:-1; /* needed to keep in the background */
                background: url(${useStyleBg}) center center;
                -webkit-background-size: cover;
                -moz-background-size: cover;
                -o-background-size: cover;
                background-size: cover;
            }
          `;

        var styleSheet = document.createElement("style");
        styleSheet.innerText = styles;
        document.head.appendChild(styleSheet);
      }

      document.title = data.name.replace(/(<([^>]+)>)/gi, " ").trim();
      window.setTimeout(() => {
        let elHeight = document.body.scrollHeight;
        if (window.self !== window.top) {
          window.parent.postMessage(`benchvoteResize-${elHeight}`);
        }
      }, 500);
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !prevProps.competition.dataAvailable &&
      this.props.competition.dataAvailable
    ) {
      this.resizeContainer();
      console.log("Submitting session tracking");
      this.props.dispatch(
        trackSession(this.props.competition.data.id, this.props.dispatch)
      );

      // CSS FILES
      var fileref = document.createElement("link");
      fileref.setAttribute("rel", "stylesheet");
      fileref.setAttribute("type", "text/css");
      fileref.setAttribute("href", this.props.competition.data.cssContentUrl);
      if (typeof fileref != "undefined") {
        document.getElementsByTagName("head")[0].appendChild(fileref);
      }

      // FONT CSS FILES
      var fileref = document.createElement("link");
      fileref.setAttribute("rel", "stylesheet");
      fileref.setAttribute("type", "text/css");
      fileref.setAttribute("href", this.props.competition.data.fontUrl);
      if (typeof fileref != "undefined") {
        document.getElementsByTagName("head")[0].appendChild(fileref);
      }
    }
    if (
      this.props.tracking &&
      this.props.tracking.flakeId && !prevProps.tracking.flakeId
    ) {
      const { flakeId } = this.props.tracking;
      window.parent.postMessage(`benchvoteTracking-${flakeId}`, "*");
      window.parent.postMessage(
        `benchvoteTrackingApi-${encodeURI(API_V2_URL)}`,
        "*"
      );
      window.parent.postMessage(
        `benchvoteCompetition-${this.props.competition.data.id}`,
        "*"
      );
    }
    if (!prevProps.competition.dataAvailable && this.props.competition && this.props.competition.data
        && this.props.competition.tippingLeaderboards.length == 0) {
          if (this.props.competition.data.type === "tipping") {
            this.props.dispatch(
                getTippingStandings(
                    this.props.competition.data.id,
                    localStorage.getItem('token'),
                    {
                      page: 0,
                      limit: this.showOnlyLeaderboard() ? 20 : 15,
                      authenticated: (!this.showOnlyLeaderboard() && this.props.auth.loggedIn)
                    },
                    this.props.dispatch
                )
            );
          } else if (this.props.competition.data.type === "leaderboard") {
            this.props.dispatch(
                getLeaderboardStandings(
                    this.props.competition.data.id,
                    localStorage.getItem('token'),
                    {
                      page: 0,
                      limit: this.showOnlyLeaderboard() ? 20 : 15,
                      authenticated: (!this.showOnlyLeaderboard() && this.props.auth.loggedIn)
                    },
                    this.props.dispatch
                )
            );
          }
    }
    if (
      this.props.auth &&
      this.props.tracking &&
      this.props.auth.loggedIn && !prevProps.auth.loggedIn
    ) {
      const { idUser } = this.props.auth.user;
      const { flakeId } = this.props.tracking;
      console.log("Submitting optin tracking");
      if (this.props.competition.data) {
        this.props.dispatch(
          trackOptin(
            this.props.competition.data.id,
            idUser,
            flakeId,
            this.props.dispatch
          )
        );
        window.parent.postMessage(`benchvoteUser-${idUser}`, "*");
      }
    }
    if (
      this.props.competition &&
      this.props.tracking &&
      this.props.competition.submittedIdVote &&
        !prevProps.competition.submittedIdVote
    ) {
      const idVote = this.props.competition.submittedIdVote;
      const { idUser } = this.props.auth.user;
      const { flakeId } = this.props.tracking;
      console.log("Submitting vote tracking");
      this.props.dispatch(
        trackVote(
          this.props.competition.data.id,
          idVote,
          idUser,
          flakeId,
          this.props.dispatch
        )
      );
      window.parent.postMessage(`benchvoteVote-${idVote}`, "*");
    }
    if (
      this.props.competition &&
      this.props.competition.data &&
      this.props.competition.data.id > 0 &&
      !this.state.codeInjected
    ) {
      const { injectionCode } = this.props.competition.data;
      const bodyHead = injectionCode.find(
        ic => ic.page === "global" && ic.place === "head"
      );
      const bodyBody = injectionCode.find(
        ic => ic.page === "global" && ic.place === "body"
      );
      const bodyFooter = injectionCode.find(
        ic => ic.page === "global" && ic.place === "footer"
      );
      if (bodyHead && bodyHead.code) {
        $(bodyHead.place).append(bodyHead.code);
      }
      if (bodyBody && bodyBody.code) {
        $(bodyBody.place).append(bodyBody.code);
      }
      if (bodyFooter && bodyFooter.code) {
        $(bodyFooter.place).append(bodyFooter.code);
      }
      this.setState({
        codeInjected: true
      });
    }

    //to load language
    if (!prevProps.competition.data && this.props.competition.data) {

      this.props.dispatch(
        loadLocale(this.props.competition.data.localeId, this.props.dispatch)
      );
    }
    if (
      this.props.locale.lang &&
      Object.keys(this.props.locale.lang).length !== 0 &&
      !prevProps.locale.lang.pick_now
    ) {
      setTimeout(()=>{
        this.setState({...this.state,loader:false})
      },500)
      i18n.use(initReactI18next).init({
        lang: this.props.competition.data.localeId,
        fallbackLng: this.props.competition.data.localeId,
        debug: true,
        ns: ["common"],
        defaultNS: "common",
        interpolation: {
          escapeValue: false // not needed for react as it escapes by default
        },
        resources: {
          [this.props.competition.data.localeId]: {
            common: this.props.locale.lang
          }
        }
      });
    }
  }

  componentDidMount() {
    // let hash = this.props.location.hash;
    // hash = hash ? hash.replace(/(^#)/g, "") : "";
    let hash = getUrlWord(this.props.match.url, this.props.location.hash);
    if (hash) {
      this.props.dispatch(getAllRounds(hash, this.props.dispatch));
      this.setState({ hash });
      this.setState({...this.state,loader:true})
    }
  }

  handleAcceptTnc = e => {
    let newState = { ...this.state };
    newState.tncs = e.target.checked;
    this.setState(newState);
  };

  handleAcceptOptin = e => {
    let newState = { ...this.state };
    newState.optin = e.target.checked;
    this.setState(newState);
  };

  handleAcceptTncs = () => {
    const { t } = this.props;
    let { tncs, optin } = this.state;
    if (!tncs) {
      this.setState({
        tncError: t("terms_condition_continue")
      });
      return;
    } else {
      this.setState({
        tncError: null
      });
    }

    this.props.dispatch(
      optinSocialCall(
        { tncs, optin },
        localStorage.getItem("token"),
        this.props.dispatch
      )
    );
  };
  onHandleRegisterLogin = type => {
    this.child.toInitial();
    if (type === "register") this.child.toRegister();
    else if (type === "login") this.child.toLogin();
  };

  showOnlyLeaderboard = () => {
    return this.props.location && (this.props.location.pathname === "/leaderboard" || this.props.location.pathname.includes('/leaderboard'));
  };

  render() {
    let {
      logginIn,
      recovering,
      user,
      optingIn,
      optedInExternal
    } = this.props.auth;
    const { comp, competition, t } = this.props;
    let { tncs, optin, tncError } = this.state;

    const loggingInDiv = logginIn ? (
      <Modal show={true} dialogClassName={"loggingInModal"}>
        <Modal.Body>
          <div className={"logging-in"}>
            <FontAwesomeIcon className="benchvote-spin" icon={faSpinner} spin />
            <Trans i18nKey="logging_in" />
          </div>
        </Modal.Body>
      </Modal>
    ) : null;

    const cookies = new Cookies();
    let modalContent = '';
    if (this.props.competition && this.props.competition.data
        && this.props.competition.data.showAgeRestrictionPopup) {
        console.log('p-nish', this.props.competition.data.showAgeRestrictionPopup);
        modalContent = (<Modal show={!cookies.get('age-restriction-' + this.props.competition.data.id)}
            dialogClassName={"loggingInModal"}>
            <Modal.Body>
                <div className={"age-restrict-dialog"}>
                    <div className={"modal-body"}>
                        <h3><Trans i18nKey="are_you_over_18" /></h3>
                    </div>
                    <div className={"modal-footer"}>
                        <button type="button" className={"btn btn-default"}
                            onClick={this.setAgeRestrictionAcceptance}><Trans i18nKey="yes" /></button>

                        <button type="button" className={"btn btn-default"}
                            onClick={this.showAgeRestrictionPopupError}><Trans i18nKey="no" /></button>
                        {this.state.showAgeRestrictionPopupErr ?
                            <div className={"modal-error"}><Trans i18nKey="are_you_over_18_err" /></div>
                            : ''
                        }
                    </div>
                </div>
            </Modal.Body>
        </Modal>);
    }


    const optinDiv =
      user &&
      JSON.stringify(user) !== "{}" &&
      !user.acceptedTncs &&
      !optedInExternal ? (
        <Modal show={true}>
          <Modal.Body>
            <div className={"logging-in"}>
              {optingIn ? (
                `${t("storing_preferences")}`
              ) : (
                <div>
                  {tncError && (
                    <div style={{ color: comp ? comp.primaryColor : "" }}>
                      {tncError}
                    </div>
                  )}
                  <div className="tncs">
                    <Checkbox
                      checked={tncs ? "checked" : ""}
                      onClick={this.handleAcceptTnc}
                    >
                      <Trans i18nKey="i_accept" />{" "}
                      <a
                        href={comp && comp.tncs ? comp.tncs : "#"}
                        target="_blank"
                      >
                        <Trans i18nKey="terms_condtion" />
                      </a>
                    </Checkbox>
                  </div>
                  {/*<div className='tncs'>*/}
                  {/*<Checkbox checked={optin ? 'checked' : ''}*/}
                  {/*onClick={this.handleAcceptOptin}>*/}
                  {/*Receive exclusive content & offers from the Adelaide Football Club and Hertz*/}
                  {/*</Checkbox>*/}
                  {/*</div>*/}
                  <div
                    style={{
                      backgroundColor: comp ? comp.buttonColor : "",
                      color: comp ? comp.primaryColor : ""
                    }}
                    className="button-modal"
                    onClick={this.handleAcceptTncs}
                  >
                    <Trans i18nKey="accept" />
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>
      ) : null;

    const recoveringDiv = recovering ? (
      <Modal show={true}>
        <Modal.Body>
          <div className={"logging-in"}>
            <FontAwesomeIcon className="benchvote-spin" icon={faSpinner} spin />
            <Trans i18nKey="sending_recovery_email" />
          </div>
        </Modal.Body>
      </Modal>
    ) : null;

    let showHeader = false;
    if (competition && competition.currentRound) {
      const {
        localPoints1,
        localPoints2,
        awayPoints1,
        awayPoints2,
        localTeam,
        awayTeam
      } = competition.currentRound;
      //if (localPoints1 || localPoints2 || awayPoints1 || awayPoints2 ||
      //  JSON.stringify(localTeam) !== '{}' || JSON.stringify(awayTeam) !== '{}') {
      showHeader = true;
      //}
    }
    // console.log('this.refs', this.refs);
    let showLoginPanel = false;
    if (comp && comp.dataCaptureEnabled == 1) {
      showLoginPanel = true;
    }
    return (
      <React.Fragment>
        { this.state.loader ?<div className={"loaderCss"}  >
        <div className={"loader"} ><FadeLoader  size={150}  color={"#ffffff"}   loading={this.state.loader}/></div>
      </div>:null }
      <div className={`content ${showLoginPanel ? 'show-login-panel' : ''}`}>
        {modalContent}
        { showLoginPanel
          ? <VoteStatus loggedIn={this.props.auth.loggedIn} onRef={ref => (this.child = ref)} />
          : '' }
        {/* {loggingInDiv} */}
        {optinDiv}
        {/* {recoveringDiv} */}
        <Header />
        {/* {showHeader && !this.showOnlyLeaderboard() && <RoundInfo />} */}
        {/* {!this.showOnlyLeaderboard() && <MarketingButtons />} */}
        <div className="panels-cont">
          {/* <div className='left-panel alternative-panel'>
                        <Login/>
                    </div> */}
          <div className="left-panel">
            <Voting onTriggerAppRegisterLogin={this.onHandleRegisterLogin} />
          </div>
          {/* <div className="right-panel">
            {comp && (comp.type == "tipping" || SHOW_USERNAME_NON_TIPPING.includes(comp.id))
              ? <TotalLeaderboard loggedIn={this.props.auth.loggedIn} showOnlyLeaderboard={this.showOnlyLeaderboard()} />
              : null}
          </div> */}
        </div>
        <Footer />
      </div>
      </React.Fragment>
    );
  }
}

//-------------------------------------------------------------------------
//-------------------- Mapping store to App's props ----------------------
//-------------------------------------------------------------------------

const mapStateToProps = (state, ownProps) => {
  const prj = state.default;
  return {
    ...ownProps,
    competition: prj.competition,
    comp: prj.competition.data,
    auth: prj.auth,
    tracking: prj.tracking,
    locale: prj.locale
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
